<template>
  <v-card class="bounceback-card">
    <v-skeleton-loader type="card-heading" :loading="loading">
      <v-card-title class="d-flex justify-space-between mb-2">
        {{ title }}
        <v-chip :color="status.color">
          {{ status.text }}
        </v-chip>
      </v-card-title>
      <v-card-subtitle>{{ period }}</v-card-subtitle>
    </v-skeleton-loader>
    <v-card-text>
      <v-skeleton-loader type="sentences" :loading="loading">
        <!-- Opacity hack to keep the same height of card -->
        <i18n
          path="report.app.bouncebackCard.redeemReminderMessage"
          :style="{ opacity: isRedeem ? 1 : 0 }"
        >
          <template #date>
            <b>{{ redeemReminderDate }}</b>
          </template>
        </i18n>
      </v-skeleton-loader>
    </v-card-text>
    <RaiGraph
      :loading="loading"
      :spec="bouncebackSpec"
      :chart-height="300"
      :elevation="0"
    />
    <RaiGraph
      :loading="loading"
      :spec="customerCountSpec"
      :chart-height="200"
      :elevation="0"
    />
    <RaiGraph
      :loading="loading"
      :spec="avgTransSpec"
      :chart-height="200"
      :elevation="0"
    />
  </v-card>
</template>

<script>
import { parseDailyReports } from "../../utils";
import { formatDatePeriod } from "@/utils/date";

import { isFuture, getDay, subDays, parseISO } from "date-fns";
import { formatDate, RaiGraph } from "@/core-ui";
import {
  buildBouncebackSpec,
  buildActualCustomerCountSpec,
  buildActualAvgTransSpec,
} from "../specs";

/**
 * Get the previous day of the week
 * @description When is the previous day of the week? 0-6 the day of the week, 0 represents Sunday.
 * @param {Date | number} date - the date to check
 * @param {number} day - day of the week
 * @returns {Date} - the date is the previous day of week
 */
function previousDay(date, day) {
  let delta = getDay(date) - day;
  if (delta <= 0) delta += 7;
  return subDays(date, delta);
}

/**
 * Get all dates between two dates
 * @param {Date|String} start - start of the range
 * @param {Date|String} end - end of the range
 * @returns {Date[]}
 */
function getDatesInRange(start, end) {
  const startDate = start instanceof Date ? start : new Date(start);
  const endDate = end instanceof Date ? end : new Date(end);
  const date = new Date(startDate.getTime());

  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

export default {
  name: "BouncebackCard",
  components: { RaiGraph },
  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      required: true,
      validator: (value) => ["earn", "redeem"].includes(value),
    },
    campaign: {
      type: Object,
      default: () => ({}),
    },
    reports: {
      type: Object,
      default: () => ({
        earnedCoupons: null,
        redeemedCoupons: null,
      }),
    },
    dailyReports: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    period() {
      return formatDatePeriod(this.startDate, this.endDate);
    },
    status() {
      if (!this.isActive && isFuture(parseISO(this.startDate))) {
        return {
          text: this.$t("report.app.bouncebackCard.status.notStarted"),
          color: "info",
        };
      }

      return {
        text: this.isActive
          ? this.$t("report.app.bouncebackCard.status.active")
          : this.$t("report.app.bouncebackCard.status.ended"),
        color: this.isActive ? "success" : undefined,
      };
    },
    bouncebackSpec() {
      const titleKey = this.isEarn ? "earnedGraphTitle" : "redeemedGraphTitle";
      return buildBouncebackSpec({
        title: this.$t(`report.app.bouncebackCard.${titleKey}`),
        values: this.coupons,
      });
    },
    customerCountSpec() {
      return buildActualCustomerCountSpec({
        title: this.$t("report.app.bouncebackCard.customerCountGraphTitle"),
        values: this.calcDailyReports,
        barWidth: { default: 5, md: 8, lg: 12 },
      });
    },
    avgTransSpec() {
      return buildActualAvgTransSpec({
        title: this.$t("report.app.bouncebackCard.avgTransactionGraphTitle"),
        values: this.calcDailyReports,
        barWidth: { default: 5, md: 8, lg: 12 },
      });
    },
    coupons() {
      const { earnedCoupons, redeemedCoupons } = this.reports;
      const coupons = this.isEarn ? earnedCoupons : redeemedCoupons ?? [];
      const couponMap = new Map(coupons.map((x) => [x.date, x.count]));
      return this.dates.map((value) => {
        // TODO: remove toISOString
        const isoString = value.toISOString();
        const date = isoString.substring(0, isoString.indexOf("T"));
        return { date, count: couponMap.get(date) ?? 0 };
      });
    },
    dates() {
      return getDatesInRange(this.startDate, this.endDate);
    },
    calcDailyReports() {
      return (
        this.dailyReports.map((report) => {
          report.customerCount = report.customerCount ?? 0;
          report.avgTrans = report.avgTrans ?? 0;
          return report;
        }) ?? []
      );
    },
    redeemReminderDate() {
      if (!this.campaign?.redeemEndOn) return "";
      // -> default date is the Thursday before the final weekend of redemption.
      const previousThursday = previousDay(
        parseISO(this.campaign.redeemEndOn),
        4
      );
      return formatDate(
        previousThursday,
        this.$t("report.app.bouncebackCard.redeemReminderDateFormat")
      );
    },
    startDate() {
      return this.isEarn
        ? this.campaign.earnStartOn
        : this.campaign.redeemStartOn;
    },
    endDate() {
      return this.isEarn ? this.campaign.earnEndOn : this.campaign.redeemEndOn;
    },
    isActive() {
      return this.isEarn ? this.campaign.earnable : this.campaign.redeemable;
    },
    isEarn() {
      return this.type === "earn";
    },
    isRedeem() {
      return this.type === "redeem";
    },
  },
};
</script>
