<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="8" lg="4">
        <VxSelect
          v-model="campaignIdQuery"
          :items="campaigns"
          :loading="campaignsLoading"
          name="campaignId"
          :label="$t('report.app.bouncebackView.campaignSelect.label')"
          :placeholder="
            $t('report.app.bouncebackView.campaignSelect.placeholder')
          "
          hide-details
          solo
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <RaiMetric
          :title="$t('report.app.bouncebackView.totalEarnedTitleMetric')"
          :value="campaignReport.earned"
          :loading="isLoading"
        />
      </v-col>
      <v-col>
        <RaiMetric
          :title="$t('report.app.bouncebackView.redeemedTitleMetric')"
          :value="campaignReport.redeemed"
          :loading="isLoading"
        />
      </v-col>
      <v-col>
        <RaiMetric
          :title="$t('report.app.bouncebackView.redemptionRateTitleMetric')"
          :value="campaignReport.redemptionRate"
          type="percent"
          :decimals="2"
          :loading="isLoading"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <BouncebackCard
          type="earn"
          :title="this.$t('report.app.bouncebackView.earnCardTitle')"
          :campaign="campaign"
          :reports="bouncebackReports"
          :daily-reports="earnDailyReports"
          :loading="isLoading"
        />
      </v-col>
      <v-col cols="12" md="6">
        <BouncebackCard
          type="redeem"
          :title="this.$t('report.app.bouncebackView.redeemCardTitle')"
          :campaign="campaign"
          :reports="bouncebackReports"
          :daily-reports="redeemDailyReports"
          :loading="isLoading"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { orderBy, sumBy } from "lodash";
import { useRouterQuery } from "@/mixins/routerQuery";
import { VxSelect, RaiMetric } from "@/core-ui";
import BouncebackCard from "./components/BouncebackCard.vue";
import {
  BOUNCEBACK_DAILY_REPORTS_QUERY,
  BOUNCEBACK_CAMPAIGNS_QUERY,
  BOUNCEBACK_REPORTS_QUERY,
} from "./graphql";

export default {
  name: "BouncebackView",
  components: {
    VxSelect,
    RaiMetric,
    BouncebackCard,
  },
  mixins: [useRouterQuery({ name: "campaignId", preserveValue: true })],
  props: {
    storeId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    dataLoading: false,
    campaignsLoading: false,
    bouncebackCampaigns: [],
    bouncebackReports: {
      earnedCoupons: [],
      redeemedCoupons: [],
    },
  }),
  apollo: {
    bouncebackCampaigns: {
      query: BOUNCEBACK_CAMPAIGNS_QUERY,
      watchLoading(value) {
        this.campaignsLoading = value;
      },
    },
    bouncebackReports: {
      query: BOUNCEBACK_REPORTS_QUERY,
      variables() {
        return { storeId: this.storeId, campaignId: this.campaignIdQuery };
      },
      watchLoading(value) {
        this.dataLoading = value;
      },
      skip() {
        return !this.storeId || !this.campaignIdQuery;
      },
    },
    earnDailyReports: {
      query: BOUNCEBACK_DAILY_REPORTS_QUERY,
      update: (data) => data.dailyReports,
      watchLoading(value) {
        this.dataLoading = value;
      },
      variables() {
        return {
          storeId: this.storeId,
          startDate: this.campaign.earnStartOn,
          endDate: this.campaign.earnEndOn,
        };
      },
      skip() {
        return !this.storeId || !this.campaign;
      },
    },
    redeemDailyReports: {
      query: BOUNCEBACK_DAILY_REPORTS_QUERY,
      update: (data) => data.dailyReports,
      watchLoading(value) {
        this.dataLoading = value;
      },
      variables() {
        return {
          storeId: this.storeId,
          startDate: this.campaign.redeemStartOn,
          endDate: this.campaign.redeemEndOn,
        };
      },
      skip() {
        return !this.storeId || !this.campaign;
      },
    },
  },
  computed: {
    campaign() {
      return this.bouncebackCampaigns.find(
        (x) => x.value === this.campaignIdQuery
      );
    },
    campaigns() {
      return orderBy(this.bouncebackCampaigns, (x) => x.earnStartOn, ["desc"]);
    },
    campaignReport() {
      const { earnedCoupons, redeemedCoupons } = this.bouncebackReports;
      const earned = sumBy(earnedCoupons, (x) => x.count);
      const redeemed = sumBy(redeemedCoupons, (x) => x.count);
      const redemptionRate = earned > 0 ? (redeemed / earned) * 100 : 0;
      return { earned, redeemed, redemptionRate };
    },
    isLoading() {
      return this.campaignsLoading || this.dataLoading;
    },
  },
  watch: {
    campaigns: {
      handler(value) {
        if (!this.campaignIdQuery) {
          const [campaign = {}] = value;
          this.campaignIdQuery = campaign?.value;
        }
      },
    },
  },
};
</script>
